<template>
  <div class="_bg-default _80w _100vh">
    <div class="_100top pa-3">
      <v-row>
        <v-col cols="12" md="3" sm="6">
          <v-card
            height="100"
            class="radius-card d-flex"
            to="/psikolog/list-survey"
          >
            <div
              style="width: 30%; height: 100%"
              class="d-flex justify-end align-center"
            >
              <v-btn fab color="yellow lighten-5" depressed>
                <v-icon color="orange">mdi-book-multiple-outline</v-icon>
              </v-btn>
            </div>
            <div
              class="d-flex flex-column justify-center ml-2 mt-5"
              v-if="allSurvey"
            >
              <h2>{{ allSurvey.data.length }}</h2>
              <p class="mt-n1 grey--text">surveys</p>
            </div>
          </v-card>
        </v-col>
        <v-col cols="12" md="3" sm="6">
          <v-card
            height="100"
            class="radius-card d-flex"
            to="/psikolog/my-survey"
          >
            <div
              style="width: 30%; height: 100%"
              class="d-flex justify-end align-center"
            >
              <v-btn fab color="purple lighten-5" depressed>
                <v-icon color="purple">mdi-book-outline</v-icon>
              </v-btn>
            </div>
            <div
              class="d-flex flex-column justify-center ml-2 mt-5"
              v-if="mySurvey"
            >
              <h2>{{ mySurvey.data.length }}</h2>
              <p class="mt-n1 grey--text">my surveys</p>
            </div>
          </v-card>
        </v-col>
        <v-col cols="12" md="3" sm="6">
          <v-card
            height="100"
            class="radius-card d-flex"
            to="/psikolog/participant"
          >
            <div
              style="width: 30%; height: 100%"
              class="d-flex justify-end align-center"
            >
              <v-btn fab color="pink lighten-5" depressed>
                <v-icon color="pink">mdi-account-group-outline</v-icon>
              </v-btn>
            </div>
            <div
              class="d-flex flex-column justify-center ml-2 mt-5"
              v-if="participant != null"
            >
              <h2>{{ participant }}</h2>
              <p class="mt-n1 grey--text">Participant</p>
            </div>
          </v-card>
        </v-col>
        <v-col cols="12" md="3" sm="6">
          <v-card
            height="100"
            class="radius-card d-flex"
            to="/psikolog/participated"
          >
            <div
              style="width: 30%; height: 100%"
              class="d-flex justify-end align-center"
            >
              <v-btn fab color="blue lighten-5" depressed>
                <v-icon color="blue">mdi-pencil-box-multiple-outline</v-icon>
              </v-btn>
            </div>
            <div
              class="d-flex flex-column justify-center ml-2 mt-5"
              v-if="participated"
            >
              <h2>{{ participated.data.length }}</h2>
              <p class="mt-n1 grey--text">Participated</p>
            </div>
          </v-card>
        </v-col>
      </v-row>
      <div>
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "dashboardSurvey",

  data() {
    return {
      currentPage: 1,
      limit: 10,
      tab: null
    };
  },
  computed: {
    ...mapState({
      id: state => state.id,
      mySurvey: state => state.survey.my_survey,
      allSurvey: state => state.survey.survey,
      participated: state => state.survey.participated,
      participant: state => state.survey.totalParticipant
    })
  },
  mounted() {
    this.$store.dispatch("survey/mySurvey", this.id);
    this.$store.dispatch("survey/listSurvey");
    this.$store.dispatch("survey/myParticipated");
    this.$store.dispatch("survey/countTotalParticipant");
  },
  methods: {}
};
</script>

<style scoped>
.__overflow__hidden {
  overflow: hidden !important;
  box-sizing: border-box;
}
.__template__cover {
  width: 100%;
  height: 230px;
  background: #f3e5f5;
}
.__relative {
  position: relative;
  box-sizing: border-box;
  overflow: hidden;
}
.cover__ {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
</style>
